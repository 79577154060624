import React from "react";
import "../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from "react-image-gallery";
import img1 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00001.jpeg";
import img2 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00002.jpeg";
import img3 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00003.jpeg";
import img4 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00004.jpeg";
import img5 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00005.jpeg";
import img6 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00006.jpeg";
import img7 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00007.jpeg";
import img8 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00008.jpeg";
import img9 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00009.jpeg";
import img10 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00010.jpeg";
import img11 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00011.jpeg";
import img17 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00012.jpeg";
import img12 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00013.jpeg";
import img13 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00014.jpeg";
import img14 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00015.jpeg";
import img15 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00016.jpeg";
import img16 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00017.jpeg";
import img18 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00018.jpeg";
import img19 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00019.jpeg";
import img20 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00020.jpeg";
import img21 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00021.jpeg";
import img22 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00022.jpeg";
import img23 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00023.jpeg";
import img24 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00024.jpeg";
import img25 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00025.jpeg";
import img26 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00026.jpeg";
import img27 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00027.jpeg";
import img28 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00028.jpeg";
import img29 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00029.jpeg";
import img30 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00030.jpeg";
import img31 from "../../../assets/Roofing Services/Flat  Fiberglass RooFs/image00031.jpeg";

export const Flat = () => {
  const images = [
    {
      original: img1,
      thumbnail: img1,
    },
    {
      original: img2,
      thumbnail: img2,
    },
    {
      original: img3,
      thumbnail: img3,
    },
    {
      original: img4,
      thumbnail: img4,
    },
    {
      original: img5,
      thumbnail: img5,
    },
    {
      original: img6,
      thumbnail: img6,
    },
    {
      original: img7,
      thumbnail: img7,
    },
    {
      original: img8,
      thumbnail: img8,
    },
    {
      original: img9,
      thumbnail: img9,
    },
    {
      original: img10,
      thumbnail: img10,
    },
    {
      original: img11,
      thumbnail: img11,
    },
    {
      original: img12,
      thumbnail: img12,
    },
    {
      original: img13,
      thumbnail: img13,
    },
    {
      original: img14,
      thumbnail: img14,
    },
    {
      original: img15,
      thumbnail: img15,
    },
    {
      original: img16,
      thumbnail: img16,
    },
    {
      original: img17,
      thumbnail: img17,
    },
    {
      original: img18,
      thumbnail: img18,
    },
    {
      original: img19,
      thumbnail: img19,
    },
    {
      original: img20,
      thumbnail: img20,
    },
    {
      original: img21,
      thumbnail: img21,
    },
    {
      original: img22,
      thumbnail: img22,
    },
    {
      original: img23,
      thumbnail: img23,
    },
    {
      original: img24,
      thumbnail: img24,
    },
    {
      original: img25,
      thumbnail: img25,
    },
    {
      original: img26,
      thumbnail: img26,
    },
    {
      original: img27,
      thumbnail: img27,
    },
    {
      original: img28,
      thumbnail: img28,
    },
    {
      original: img29,
      thumbnail: img29,
    },
    {
      original: img30,
      thumbnail: img30,
    },
    {
      original: img31,
      thumbnail: img31,
    },
  ];
  return (
    <ImageGallery
      items={images}
      showPlayButton={false}
      thumbnailPosition={"bottom"}
    />
  );
};
