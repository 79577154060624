export const home = "/";
export const contact = "/contact";
export const services = "/services";
export const about = "/about";

export const roofingService = `${services}/Roofing-Services`;

export const SlateTileRoofs = `${roofingService}/Slate-Tile-Roofs`;
export const FlatFiberglasRoofs = `${roofingService}/Flat-Fiberglas-RooFs`;
export const LeadFlashingChimneyRepoint = `${roofingService}/Lead-Flashing-Chimney-Repoint`;
export const FaciaSoffitsGuttering = `${roofingService}/Facia-Soffits-Guttering`;

export const paintingTiling = `${services}/Painting-Tiling`;

export const ExteriorInteriorPainting = `${paintingTiling}/Exterior-Interior-Painting`;
export const TilingInDoorOutDoor = `${paintingTiling}/Tiling-InDoor-OutDoor`;

export const insulation = `${services}/Insulation`;

export const ExternalInsulation = `${insulation}/External-Insulation`;
export const AtticInsulation = `${insulation}/Attic-Insulation`;

export const sheedLandscaping = `${services}/Sheed-Landscaping`;

export const NewSheedRenew = `${sheedLandscaping}/New-Sheed-Renew`;
